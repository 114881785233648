<template>
    <section>
        <v-expansion-panel-header color="secondary-5"class="m-0 p-0 mat_expansion_panel ">
            <template v-slot:default="{ open }">
                <section class="d-flex flex-row align-center">
                    <section class="description_div poppins f14 fw500">
                        {{module.title ? module.title : 'Untitled' }} 
                    </section>
                    <v-spacer></v-spacer>
                    <v-badge
                        bordered
                        size='8'
                        inline
                        class="ml-auto"
                        :color="!hasUnfinishedItems ? 'success' : 'warning'"
                    />
                </section>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expansion-timeline ">
            <div v-for="topic in module.topics" :key="topic.uuid">
                <v-list-item dense
                    class="d-flex align-center pa-1"
                    @click.stop=""
                >
                    <v-icon small color="primary">
                        mdi-archive-outline
                    </v-icon>

                    <v-list-item-title class="poppins ml-2 f13 fw500">
                        <div class="f9 secondary-2--text roboto">
                            Topic
                        </div>
                        {{topic.title}}
                    </v-list-item-title>
                    <v-badge
                        v-if="topic.student_is_done"
                        bordered
                        class="thin-border"
                        color="success"
                        size='10'
                        inline
                    >
                    </v-badge>
                </v-list-item>
                
                <v-list-item
                    v-for="assessment in topic.topic_has_assessments" :key="assessment.uuid"
                    dense 
                    class="d-flex align-center pa-1 pl-4"
                    @click.stop="">
                    <v-icon small color="primary">
                        mdi-note-edit-outline
                    </v-icon>
                    <v-list-item-title  class="poppins ml-2 f12 fw500">
                        <div class="f9 secondary-2--text roboto">
                            Assessment
                        </div>
                        {{assessment.title}}
                        <div class="f9 secondary-2--text roboto d-flex flex-row align-center" v-if="assessment.student_is_done">
                            SCORE: {{assessment.assessment_scores[0].score}}/{{ assessment.total_points }}
                            <v-chip v-if="assessment.assessment_scores[0].checked" x-small dense color="success" class="ml-1"> CHECKED</v-chip>
                            <v-chip v-else x-small dense color="warning" class="ml-1">UNCHECKED</v-chip>
                        </div>
                    </v-list-item-title>
                    <v-spacer></v-spacer>
                    <v-badge
                        v-if="assessment.student_is_done"
                        bordered
                        class="thin-border ml-1"
                        color="success"
                        size='10'
                        inline
                    >
                    </v-badge>
                </v-list-item>
                <div v-for="sub_topic in topic.sub_topics" :key="sub_topic.uuid" class=" pl-4">
                    <v-list-item dense
                        class="d-flex align-center pa-1"
                        @click.stop=""
                    >
                        <v-icon small color="primary">
                            mdi-archive-outline
                        </v-icon>

                        <v-list-item-title class="poppins ml-2 f13 fw500">
                            <div class="f9 secondary-2--text roboto">
                                Subtopic
                            </div>
                            {{sub_topic.title}}
                        </v-list-item-title>

                        <v-badge
                            v-if="sub_topic.student_is_done"
                            bordered
                            class="thin-border"
                            color="success"
                            size='10'
                            inline
                        >
                        </v-badge>
                    </v-list-item>
                    <v-list-item
                        v-for="assessment in sub_topic.sub_topic_has_assessments"  :key="assessment.uuid"
                        dense 
                        class="d-flex align-center pa-1 pl-4"
                        @click.stop="">
                        <v-icon small color="primary">
                            mdi-note-edit-outline
                        </v-icon>
                        <v-list-item-title  class="poppins ml-2 f12 fw500">
                            <div class="f9 secondary-2--text roboto">
                                Assessment
                            </div>
                            {{assessment.title}}
                        </v-list-item-title>
                        <v-spacer></v-spacer>
                        <div v-if="assessment.student_is_done" class="roboto d-flex flex-column align-center f13 fw500">
                            <div class="f9 secondary-2--text">
                                score
                            </div>
                            {{assessment.assessment_scores[0].score}}/{{ assessment.total_points }}
                        </div>
                        <v-badge
                            v-if="assessment.student_is_done"
                            bordered
                            class="thin-border"
                            color="success"
                            size='10'
                            inline
                        >
                        </v-badge>
                    </v-list-item>
                </div>
            </div>
            <v-list-item dense
                v-for="module_evaluation in module.module_evaluations"
                :key="module_evaluation.uuid"
                class="d-flex align-center pa-1"
                @click.stop=""
            >
                <v-icon small color="primary">
                    mdi-file-document-edit-outline
                </v-icon>

                <v-list-item-title class="poppins ml-2 f13 fw500">
                    <div class="f9 secondary-2--text roboto">
                        Module Evaluation
                    </div>
                    {{module_evaluation.title}}
                </v-list-item-title>

                <v-badge
                    v-if="module_evaluation.student_is_done"
                    bordered
                    class="thin-border"
                    color="success"
                    size='10'
                    inline
                >
                </v-badge>
            </v-list-item>
        </v-expansion-panel-content>
    </section>
</template>

<style scoped>
.v-expansion-panel-header.mat_expansion_panel {
  align-items: start !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.description_div {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.expansion-timeline .v-expansion-panel-content__wrap {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.topic.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}
.subtopic.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}
.assessments.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}

.v-badge.thin-border .v-badge__badge::after {
  border-color: #BDBDBD !important;
  border-width: 1px !important;
  margin: 1px !important;
}

</style>

<script>

export default {
    props: ['module', 'completed'],

    computed: {
        hasUnfinishedItems() {
            // Iterate through topics, assessments, and subtopics
            for (const topic of this.module.topics) {
                if (!topic.student_is_done) {
                    return true; // If any topic is not done, return true
                }
                for (const assessment of topic.topic_has_assessments) {
                    if (!assessment.student_is_done) {
                        return true; // If any assessment is not done, return true
                    }
                }
                for (const subtopic of topic.sub_topics) {
                    if (!subtopic.student_is_done) {
                        return true; // If any subtopic is not done, return true
                    }
                    for (const subAssessment of subtopic.sub_topic_has_assessments) {
                        if (!subAssessment.student_is_done) {
                            return true; // If any sub-assessment is not done, return true
                        }
                    }
                }
            }
            // Check module evaluations
            for (const moduleEvaluation of this.module.module_evaluations) {
                if (!moduleEvaluation.student_is_done) {
                    return true; // If any module evaluation is not done, return true
                }
            }
            return false; // If all items are done, return false
        }
    },
}
</script>